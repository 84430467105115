import { createContext, useContext, useState } from 'react';

interface IAppStateContext {
  appState: {
    filterSidebarVisibility: boolean;
    productFilterSidebarVisibility: boolean;
    listItemsPerRow: number;
    minPrice: string;
    maxPrice: string;
    scrollToElementOnBrowserBack: string | undefined | null;
  };
  toggleProductFilterSidebar: (value: boolean) => boolean;
  toogleFilterSidebar: (value: boolean) => boolean;
  setListItemsPerRow: (value: number) => number;
  setMinPrice: (value: string) => string;
  setMaxPrice: (value: string) => string;
  setElementToScrollOnBack: (value: string) => void;
  resetElementToScrollOnBack: () => void;
}

export interface IAppState {
  filterSidebarVisibility?: boolean;
  productFilterSidebarVisibility?: boolean;
  listItemsPerRow: number;
  minPrice: string;
  maxPrice: string;
  scrollToElementOnBrowserBack: string | null | undefined;
}

const initAppState: IAppState = {
  filterSidebarVisibility: false,
  productFilterSidebarVisibility: false,
  listItemsPerRow: 1,
  minPrice: '1',
  maxPrice: '100000',
  scrollToElementOnBrowserBack: null
};
// @ts-ignore
const AppStateContext = createContext<IAppStateContext>(null);

// @ts-ignore
export const AppStateProvider = ({ children }) => {
  const [appState, setAppState] = useState(initAppState);

  const toogleFilterSidebar = (value = false) =>
    setAppState({ ...appState, filterSidebarVisibility: value });

  const toggleProductFilterSidebar = (value = false) =>
    setAppState({ ...appState, productFilterSidebarVisibility: value });

  const setListItemsPerRow = (value = 1) => setAppState({ ...appState, listItemsPerRow: value });
  const setElementToScrollOnBack = (elemId: string | null) =>
    setAppState({ ...appState, scrollToElementOnBrowserBack: elemId });
  const setMinPrice = (value = '1') => setAppState({ ...appState, minPrice: value });
  const setMaxPrice = (value = '100000') => setAppState({ ...appState, maxPrice: value });

  const resetElementToScrollOnBack = () => setElementToScrollOnBack(null);

  const context = {
    appState,
    toogleFilterSidebar,
    toggleProductFilterSidebar,
    setListItemsPerRow,
    setElementToScrollOnBack,
    setMinPrice,
    setMaxPrice,
    resetElementToScrollOnBack
  };
  return (
    // @ts-ignore
    <AppStateContext.Provider value={context}>{children}</AppStateContext.Provider>
  );
};

export const useAppSettings = () => useContext(AppStateContext);
