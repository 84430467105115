import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

export const INITIAL_PRICE = { min: '0', max: '100000' };

interface ISearchParamsContext {
  searchParams: ISearchParams;
  setSearchParams: (sp: ISearchParams) => ISearchParams;
  updateSorting: (sorting: number | string) => ISearchParams;
  updateScore: (score: number | string) => ISearchParams;
  updateGender: (gender: number | string) => ISearchParams;
  updateDropDownFilters: (filter: { name: string; value: string }) => ISearchParams;
  updatePage: (value: number) => ISearchParams;
  updatePrice: (price: PriceFilter) => ISearchParams;
  resetSelectedFilters: () => ISearchParams;
}

export type DropDownFilter = {
  name: string;
  value: string;
};

export type PriceFilter = {
  min: string;
  max: string;
};

export interface ISearchParams {
  slug?: string;
  page?: number;
  selectedFilters: {
    gender: string | null;
    overallScore?: number | null;
    sorting: number;
    dropdownFilters: DropDownFilter[];
    price: PriceFilter;
  };
  tag?: string;
  subcat?: string;
}

const initSearchParams: ISearchParams = {
  slug: undefined,
  page: undefined,
  selectedFilters: {
    gender: null,
    overallScore: null,
    sorting: 1,
    dropdownFilters: [],
    price: INITIAL_PRICE
  }
};
// @ts-ignore
const SearchParamsStateContext = createContext<ISearchParamsContext>(null);

type Props = {
  children: ReactNode;
  initialSp: ISearchParams;
};

// @ts-ignore
export const SearchParamsProvider = ({ children, initialSp }: Props) => {
  const [searchParams, setSearchParams] = useState(initialSp || initSearchParams);

  useEffect(() => {
    setSearchParams(initialSp);
  }, [initialSp]);

  const updateSorting = (value: number) => {
    if (searchParams.selectedFilters.sorting !== value)
      setSearchParams({
        ...searchParams,
        selectedFilters: { ...searchParams?.selectedFilters, sorting: value }
      });
  };
  const updateScore = (value: number) => {
    setSearchParams({
      ...searchParams,
      selectedFilters: {
        ...searchParams?.selectedFilters,
        overallScore: Number(value === searchParams?.selectedFilters.overallScore ? null : value)
      }
    });
  };

  const updatePage = (value: number) => {
    if (searchParams.page !== value) {
      setSearchParams({
        ...searchParams,
        page: value
      });
    }
  };

  const updateGender = (newGender: string) => {
    setSearchParams({
      ...searchParams,
      selectedFilters: {
        ...searchParams?.selectedFilters,
        dropdownFilters: [],
        overallScore: 0,
        // sorting stays
        price: INITIAL_PRICE,
        // @ts-ignore
        gender: newGender === searchParams?.selectedFilters.gender ? null : newGender
      }
    });
  };

  const resetMapping = {
    product_tag: ['brandName', 'availableSizes', 'color']
  };

  const updatePrice = (newPrice: PriceFilter) => {
    return {
      ...searchParams,
      selectedFilters: {
        ...searchParams?.selectedFilters,
        price: newPrice
      }
    };
  };

  // @ts-ignore
  const updateDropDownFilters = ({ name, value }) => {
    let newSearchParams;

    const item = (searchParams?.selectedFilters?.dropdownFilters || []).find(
      (_) => _.name === name && _.value === value
    );

    if (item) {
      // remove it
      newSearchParams = {
        ...searchParams,
        selectedFilters: {
          ...searchParams?.selectedFilters,
          dropdownFilters: [
            // @ts-ignore
            ...searchParams?.selectedFilters.dropdownFilters.filter(
              (_) => _.name !== name && _.value !== value
            ),
            // @ts-ignore
            ...searchParams?.selectedFilters.dropdownFilters.filter(
              (_) => _.name === name && _.value !== value
            )
          ]
        }
      };
    } else {
      newSearchParams = {
        ...searchParams,
        selectedFilters: {
          ...searchParams?.selectedFilters,
          // @ts-ignore
          dropdownFilters: [...searchParams?.selectedFilters?.dropdownFilters, { name, value }]
        }
      };
    }

    // check do we need to reset other dependent filters
    // @ts-ignore
    if (resetMapping[name]) {
      newSearchParams = {
        ...newSearchParams,
        selectedFilters: {
          ...newSearchParams?.selectedFilters,
          dropdownFilters: newSearchParams?.selectedFilters?.dropdownFilters.filter(
            // @ts-ignore
            (elem) => !resetMapping[name].includes(elem.name)
          )
        }
      };
    }
    setSearchParams(newSearchParams);
  };

  const resetSelectedFilters = () => {
    setSearchParams({
      ...searchParams,
      selectedFilters: {
        dropdownFilters: [],
        gender: null,
        overallScore: 0,
        sorting: 1,
        price: INITIAL_PRICE
      }
    });
  };

  const context = {
    resetSelectedFilters,
    searchParams,
    setSearchParams,
    updateDropDownFilters,
    updateGender,
    updateScore,
    updateSorting,
    updatePage,
    updatePrice
  };
  return (
    // @ts-ignore
    <SearchParamsStateContext.Provider value={context}>
      {children}
    </SearchParamsStateContext.Provider>
  );
};

export const useSearchParams = () => useContext(SearchParamsStateContext);
