import { AccessObjectByStringKey } from '../types';

enum CookieMapping {
  'Facebook_Pixel' = 1,
  'Google_Analytics' = 2,
  'Google_Tag_Manager' = 3,
  'Google_Webfonts' = 4,
  'Hotjar' = 5,
  'Mailchimp' = 6,
  'SurveyMonkey' = 7,
  'Usercentrics_Consent_Management_Platform' = 8,
  'Criteo' = 9,
  'Google_Adsense' = 10,
}

export const mapConsentFromCookie = (cookie?: string) => {
  if (!cookie) return;
  try {
    const parsed = JSON.parse(cookie);
    return Object.entries(parsed).reduce((acc: AccessObjectByStringKey, [key, val]) => {
      acc[CookieMapping[key as keyof typeof CookieMapping]] = Boolean(parseInt(val as string, 10));
      return acc;
    }, {});
  } catch (e) {
    // do nothing
  }
};

export const mapConsentToCookie = (consentObj = {}) =>
  Object.entries(consentObj).reduce((acc: { [key: string]: number }, [key, val]) => {
    if (CookieMapping[key as keyof typeof CookieMapping]) {
      acc[CookieMapping[key as keyof typeof CookieMapping]] = Number(val);
    }
    return acc;
  }, {});
