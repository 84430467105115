export const setCookie = (
  name: string,
  value: string,
  expires?: string,
  expiresInDay?: number
): void => {
  if (typeof window === 'undefined' || !name || !value) return;

  if (!expires) {
    const d = new Date();
    d.setTime(d.getTime() + (expiresInDay || 360) * 24 * 60 * 60 * 1000); // set to full year
    expires = d.toUTCString();
  }

  document.cookie = `${name}=${value};expires="${expires}";path=/`;
};

export const setSessionCookie = (name: string, value: string): void => {
  if (typeof window === 'undefined' || !name || !value) return;
  document.cookie = `${name}=${value};path=/`;
};

export const deleteCookie = (name: string): void => {
  if (typeof window === 'undefined' || !name) return;

  const d = new Date();
  d.setTime(d.getTime() - 1); // set cookie time to past
  const expires = d.toUTCString();

  document.cookie = `${name}=;expires="${expires}";path=/`;
};

export const getCookie = (name: string, source: string): string | void => {
  if (!name || !source) return;

  const match = source.match(new RegExp('(^| )' + name + '=([^;]+)'));

  if (match) return match[2]; // match = [ '_mbb_usr_gndr=herren', 'herren', index: 0, input: '_mbb_usr_gndr=herren' ]
};
