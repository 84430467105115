import '../scss/main.scss';
import Head from 'next/head';
import cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { AppProps } from 'next/app';
import { AccessObjectByStringKey } from '../types';
import { mapConsentFromCookie, mapConsentToCookie } from '../utils/cmp';
import { getCookie, setCookie } from '../utils/cookies';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { SearchParamsProvider } from '../store/searchparams-context';
import { AppStateProvider, useAppSettings } from '../store/appstate-context';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import Link from 'next/link';

const FacebookPixel = dynamic(import('../components/common/tracking/facebook-pixel'), {
  ssr: false
});

const UC_VIEW = 'UC_UI_VIEW_CHANGED';
const UC_CONSENT = 'uc_consent';
const UC_INITIALIZED = 'UC_UI_INITIALIZED';

// @ts-ignore
function Layout({ children }) {
  const router = useRouter();
  const { appState, resetElementToScrollOnBack } = useAppSettings();
  const backButtonClicked = useRef(false);
  const scrollToElementOnBrowserBack = useRef(appState.scrollToElementOnBrowserBack);

  useEffect(() => {
    scrollToElementOnBrowserBack.current = appState.scrollToElementOnBrowserBack;
  }, [appState.scrollToElementOnBrowserBack]);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      if (backButtonClicked.current) {
        if (scrollToElementOnBrowserBack.current) {
          try {
            const productToScroll = document.querySelector(
              `#${scrollToElementOnBrowserBack.current}`
            );
            scrollToElementOnBrowserBack.current = null;
            resetElementToScrollOnBack(); // reset scrolling element as we do not want to track it further

            if (productToScroll && typeof productToScroll.scrollIntoView === 'function') {
              productToScroll.scrollIntoView({ behavior: 'auto' });
            }
          } catch (e) {
            // log this case
            console.log('ERROR OCCURED ON FF APP', e);
          }
        }

        backButtonClicked.current = false; // reset back button
      }
    });
  }, [router, backButtonClicked, appState.scrollToElementOnBrowserBack]);

  useEffect(() => {
    router.beforePopState(() => {
      // track user clicked back button so we can handle everything on routeChangeComplete event.
      backButtonClicked.current = true;

      return true;
    });
  }, [router]);
  return <>{children}</>;
}

export default function MyApp({
  Component,
  pageProps,
  err,
  __N_PREVIEW
}: AppProps & { err: any } & { __N_PREVIEW: boolean }) {
  const [showDebugger, toggleDebugger] = useState(false);
  const router = useRouter();

  const [userConsent, setConsent] = useState({
    CMPLoaded: false,
    Google_Adsense: false,
    Facebook_Pixel: false
  });

  useEffect(() => {
    const fromCookie = getCookie(UC_CONSENT, document.cookie);
    const unmappedConsent = mapConsentFromCookie(fromCookie as string) as AccessObjectByStringKey;
    if (unmappedConsent) {
      const consent = { ...unmappedConsent, CMPLoaded: false };
      setConsent((prevState) => ({ ...prevState, ...consent }));
      window[UC_CONSENT] = unmappedConsent;
      window.dataLayer.push({ event: 'consents_loaded' });
    }

    window.addEventListener(UC_VIEW, handleCMP(unmappedConsent));
    window.addEventListener(UC_INITIALIZED, handleCMP(unmappedConsent));
    // in case when cmp somehow loads before we set the listener here ^
    if (window.CMPInitFired) {
      window.dispatchEvent(new Event(UC_INITIALIZED));
    }
    if (window.CMPViewFired) {
      window.dispatchEvent(new Event(UC_VIEW));
    }
  }, []);

  useEffect(() => {
    const debugEnabled = cookies.get('debug_bgb');
    if (debugEnabled) toggleDebugger(true);
  }, []);

  useEffect(() => {
    const handleStart = () => NProgress.start();
    const handleStop = () => NProgress.done();

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  const handleCMP = (unmappedConsent: AccessObjectByStringKey) => () => {
    if (typeof window.UC_UI === 'object') {
      const consents = window.UC_UI.getServicesBaseInfo().reduce(
        (acc: { [key: string]: boolean }, curr: { consent: { status: boolean }; name: string }) => {
          const key = curr.name.split(' ').join('_');
          acc[key] = curr.consent.status;
          return acc;
        },
        {}
      );
      const mappedConsent = mapConsentToCookie(consents);
      window[UC_CONSENT] = consents;
      setCookie(UC_CONSENT, JSON.stringify(mappedConsent));
      const consent = { ...consents, CMPLoaded: true };
      setConsent((prevState) => ({ ...prevState, ...consent }));
      if (!unmappedConsent) {
        window.dataLayer.push({ event: 'consents_loaded' });
      }
    }
  };

  const syncData = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    fetch('/api/sync');
  };

  // check if url has parameters, and if yes then add robots no index except brand product pages
  //const addRobotsNoIndex = router.asPath.indexOf('?') > -1;

  // check if url has parameters, and if yes then add robots no index except brand product pages
  const addRobotsNoIndex = router.asPath.indexOf('?filter=') > -1;
  // check if url has parameters, and if yes then add robots no index
  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
        />
        <meta name='keywords' content='Keywords' />

        {addRobotsNoIndex && <meta name='robots' content='noindex' />}
        {!addRobotsNoIndex && <meta name='robots' content='index,follow' />}
        {/*<title>Next.js PWA Example</title>*/}

        {/*<link rel='manifest' href='/manifest.json' />*/}
        {/*<link href='/icons/favicon-16x16.png' rel='icon' type='image/png' sizes='16x16' />*/}
        {/*<link href='/icons/favicon-32x32.png' rel='icon' type='image/png' sizes='32x32' />*/}
        {/*<link rel='apple-touch-icon' href='/apple-icon.png'></link>*/}
        {/*<meta name='theme-color' content='#317EFB' />*/}
        <script
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag() {
            dataLayer.push(arguments);
        }

        // set „denied" as default for both ad and analytics storage,
        gtag("consent", "default", {
            ad_storage: "denied",
            analytics_storage: "denied",
            wait_for_update: 2000 // milliseconds to wait for update
        });

        // Enable ads data redaction by default [optional]
        gtag("set", "ads_data_redaction", true);
                window.dataLayer.push({
                  originalLocation:
                      document.location.protocol +
                      '//' +
                      document.location.hostname +
                      document.location.pathname +
                      document.location.search,
                });

                (function(w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                  var f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s),
                      dl = l != 'dataLayer' ? '&l=' + l : '';
                  j.async = true;
                  j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
               `
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.addEventListener('UC_UI_INITIALIZED', () => {
              window.CMPLoaded = true;
              window.dataLayer.push({event: 'CMPLoaded'});
              window.CMPInitFired = true;
            });
            window.addEventListener('UC_UI_VIEW_CHANGED', () => {
              window.CMPViewFired = true;
            });
          `
          }}
        />
        <script
          key='usercentrics-cmp'
          id='usercentrics-cmp'
          data-settings-id='_M6vz_EHK'
          src='https://app.usercentrics.eu/browser-ui/latest/loader.js'
          async
        />
        {userConsent.Google_Adsense && (
          <script
            async
            src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9081715620912792'
            crossOrigin='anonymous'
          ></script>
        )}
      </Head>

      <SearchParamsProvider initialSp={pageProps.searchParams}>
        <AppStateProvider>
          <Layout>
            <Script src='//platform.instagram.com/en_US/embeds.js'></Script>
            {showDebugger && (
              <div
                className='bg-success text-white text-center'
                style={{ position: 'relative', zIndex: 240 }}
              >
                <Link href='/api/sync'>
                  <a className='text-white' onClick={syncData}>
                    CLICK HERE TO SYNC
                  </a>
                </Link>
              </div>
            )}
            {__N_PREVIEW && (
              <div className='text-center' style={{ position: 'relative', zIndex: 240 }}>
                This page is a preview
                <Link href='/api/exit-preview'>
                  <a className='underline hover:text-cyan duration-200 transition-colors'>
                    Click here
                  </a>
                </Link>
                to exit preview mode.
              </div>
            )}
            <Component {...pageProps} err={err} />
          </Layout>
        </AppStateProvider>
      </SearchParamsProvider>
      {userConsent.Facebook_Pixel && (
        <FacebookPixel
          event={pageProps?.fbData?.event}
          data={pageProps?.fbData?.data}
          eventData={pageProps?.fbData?.eventData}
        />
      )}
    </>
  );
}
